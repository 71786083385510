import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//---------Below 3 are for testing!--------//
// // import Header from "./core/Header";
// // import SearchedProducts from "./core/SearchedProducts";
// // import PaymentModeSelection from "./components/PaymentModeselection";

// import signup from "./user/SignUp";
// import signin from "./user/SignIn";
// import Home from "./core/Home";
// import Shop from "./core/Shop";
// import SingleProduct from "./core/SingleProduct";
// import Cart from "./core/Cart";
// import PrivateRoute from "./auth/PrivateRoute";
// import Dashboard from "./user/UserDashboard";
// import AdminRoute from "./auth/AdminRoute";
// import AdminDashboard from "./user/AdminDashboard";
// import AddCategory from "./admin/AddCategory";
// import AddProduct from "./admin/AddProduct";
// import Orders from "./admin/Orders";
// import Profile from "./user/Profile";
// import ManageProducts from "./admin/ManageProducts";
// import UpdateProduct from "./admin/UpdateProduct";
// import UpdateCategory from "./admin/UpdateCategory";
// import ForgotPassword from "./components/forgotPassword_Text";
// import ResetPassword from "./components/reserPassword_Text";
// import ShippingAddress from "./core/ShippingAddress";
// import PlaceOrder from "./core/PlaceOrder";
// import Footer from "./core/Footer";
// import Wishlist from "./user/Wishlist";
// import Menu from "./core/Menu";
// import UserPurchaseHistory from "./user/UserPurchaseHistory";
// import Payment from "./core/Payment";
// import UserInfo from "./user/UserInfo";
// import LogOut from "./core/logoutPage";
// import ScrollToTop from "./components/ScrollToTop";

//Using lazy
const preSignup = lazy(() => import("./user/Pre-signup"));
const signup = lazy(() => import("./user/SignUp"));
const signin = lazy(() => import("./user/SignIn"));
const Home = lazy(() => import("./core/Home"));
const Shop = lazy(() => import("./core/Shop"));
const SingleProduct = lazy(() => import("./core/SingleProduct"));
const Cart = lazy(() => import("./core/Cart"));
const PrivateRoute = lazy(() => import("./auth/PrivateRoute"));
const Dashboard = lazy(() => import("./user/UserDashboard"));
const AdminRoute = lazy(() => import("./auth/AdminRoute"));
const AdminDashboard = lazy(() => import("./user/AdminDashboard"));
const AddCategory = lazy(() => import("./admin/AddCategory"));
const AddProduct = lazy(() => import("./admin/AddProduct"));
const Orders = lazy(() => import("./admin/Orders"));
const Profile = lazy(() => import("./user/Profile"));
const ManageProducts = lazy(() => import("./admin/ManageProducts"));
const ManageCategory = lazy(() => import("./admin/ManageCategory"));
const UpdateProduct = lazy(() => import("./admin/UpdateProduct"));
const UpdateCategory = lazy(() => import("./admin/UpdateCategory"));
const ForgotPassword = lazy(() => import("./components/forgotPassword_Text"));
const ResetPassword = lazy(() => import("./components/reserPassword_Text"));
const ShippingAddress = lazy(() => import("./core/ShippingAddress"));
const PlaceOrder = lazy(() => import("./core/PlaceOrder"));
const Footer = lazy(() => import("./core/Footer"));
const Wishlist = lazy(() => import("./user/Wishlist"));
const UserPurchaseHistory = lazy(() => import("./user/UserPurchaseHistory"));
const Payment = lazy(() => import("./core/Payment"));
const UserInfo = lazy(() => import("./user/UserInfo"));
const LogOut = lazy(() => import("./core/logoutPage"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop"));
const Navbar = lazy(() => import("./core/Navbar"));
const SuccessPayment = lazy(() => import("./core/SuccessPayment"));
const FailPayment = lazy(() => import("./core/FailPayment"));

const Pol_Service = lazy(() => import("./policies/Services"));
const Pol_Return = lazy(() => import("./policies/Return"));
const Pol_Shipping = lazy(() => import("./policies/Shipping"));
const Pol_Privacy = lazy(() => import("./policies/Privacy"));
const Pol_TnC = lazy(() => import("./policies/TnC"));

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="col text-center p-5">
          <h1 style={{ fontSize: 20, fontWeight: "bold", color: "gray" }}>
            {" "}
            C <i className="fas fa-stroopwafel fa-spin"></i>{" "}
            <i className="fas fa-stroopwafel fa-spin"></i> K E R Y
          </h1>
        </div>
      }
    >
      <BrowserRouter>
        <div className="grid-container">
          {/* <Menu /> */}
          <Navbar />

          <ToastContainer
            newestOnTop={true}
            position="bottom-center"
            autoClose={3000}
          />
          <main>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={Home} />

              <Route path="/signin" exact component={signin} />

              <Route path="/pre-signup" exact component={preSignup} />

              {/* <Route
                path="/signup/:signupemail/:token"
                exact
                component={signup}
              /> */}

              <Route path="/signup" exact component={signup} />

              <Route path="/forgotPassword" exact component={ForgotPassword} />
              <Route
                path="/forgottoresetpassword/:token"
                exact
                component={ResetPassword}
              />
              <Route path="/shop" exact component={Shop} />
              <Route
                path="/product/:productId"
                exact
                component={SingleProduct}
              />
              <Route path="/cart" exact component={Cart} />

              <Route path="/policy/services" exact component={Pol_Service} />
              <Route path="/policy/return" exact component={Pol_Return} />
              <Route path="/policy/shipping" exact component={Pol_Shipping} />
              <Route path="/policy/privacy" exact component={Pol_Privacy} />
              <Route
                path="/policy/terms-conditions"
                exact
                component={Pol_TnC}
              />

              <PrivateRoute
                path="/shipping"
                exact
                component={ShippingAddress}
              />
              <PrivateRoute path="/placeOrder" exact component={PlaceOrder} />

              <PrivateRoute path="/payment" exact component={Payment} />
              <PrivateRoute
                path="/payment/success"
                exact
                component={SuccessPayment}
              />
              <PrivateRoute
                path="/payment/fail"
                exact
                component={FailPayment}
              />
              <Route path="/logout" exact component={LogOut} />

              <PrivateRoute
                path="/user/dashboard"
                exact
                component={Dashboard}
              />
              <PrivateRoute path="/profile/:userId" exact component={Profile} />
              <PrivateRoute
                path="/user/dashboard/wishlist"
                exact
                component={Wishlist}
              />
              <PrivateRoute
                path="/user/dashboard/userInfo"
                exact
                component={UserInfo}
              />
              <PrivateRoute
                path="/user/dashboard/purchaseHistory"
                exact
                component={UserPurchaseHistory}
              />

              <AdminRoute path="/admin/orders" exact component={Orders} />
              <AdminRoute
                path="/admin/dashboard"
                exact
                component={AdminDashboard}
              />
              <AdminRoute
                path="/create/category"
                exact
                component={AddCategory}
              />
              <AdminRoute path="/create/product" exact component={AddProduct} />
              <AdminRoute
                path="/admin/products"
                exact
                component={ManageProducts}
              />
              <AdminRoute
                path="/admin/product/update/:productId"
                exact
                component={UpdateProduct}
              />
              <AdminRoute
                path="/admin/category/update/:categoryId"
                exact
                component={UpdateCategory}
              />
              <AdminRoute
                path="/admin/categories"
                exact
                component={ManageCategory}
              />
            </Switch>
          </main>

          <Footer />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};
export default App;
